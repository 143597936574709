import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from 'src/assets/logo1.jpg'; // Ensure you have the logo in the correct folder
import 'bootstrap/dist/css/bootstrap.min.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState(Array(8).fill('')); // Array for individual code inputs
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState('');
  const [timer, setTimer] = useState(180); // 3-minute timer in seconds
  const [isTimerRunning, setIsTimerRunning] = useState(false); // Timer running state
  const navigate = useNavigate();

  useEffect(() => {
    let timerInterval;

    // Start the timer if it's running
    if (isTimerRunning) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(timerInterval);
            setIsTimerRunning(false); // Stop the timer
            setMessage('Verification code expired. Please request a new code.');
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    // Cleanup the interval on component unmount
    return () => clearInterval(timerInterval);
  }, [isTimerRunning]);

  const handleRequestReset = async () => {
    try {
      const response = await axios.post('https://soft-screen.com/api/request-password-reset/', { email: email.trim() });
      setMessage(response.data.message);
      setStep(2); // Proceed to the next step for verification code
      setTimer(180); // Reset the timer to 3 minutes
      setIsTimerRunning(true); // Start the timer
    } catch (error) {
      console.error("Error in handleRequestReset:", error); // Logging
      if (error.response) {
        setMessage(error.response.data.error || 'An error occurred.');
      } else if (error.request) {
        setMessage('No response received from server.');
      } else {
        setMessage('Request failed: ' + error.message);
      }
    }
  };

  const handleVerifyCode = async () => {
    const verificationCode = code.join(''); // Combine array into a single string
    if (!verificationCode) {
      setMessage('Please enter the verification code.');
      return;
    }

    try {
      const response = await axios.post('https://soft-screen.com/api/verify-reset-code/', {
        email: email.trim(),
        code: verificationCode.trim(),
      });

      setMessage(response.data.message);
      setStep(3); // Proceed to the final step for entering a new password
      setIsTimerRunning(false); // Stop the timer
    } catch (error) {
      console.error("Error in handleVerifyCode:", error); // Logging
      if (error.response) {
        setMessage(error.response.data.error || 'An error occurred.');
      } else if (error.request) {
        setMessage('No response received from server.');
      } else {
        setMessage('Request failed: ' + error.message);
      }
    }
  };

  const handleNewPassword = async () => {
    try {
      const response = await axios.post('https://soft-screen.com/api/set-new-password/', {
        email: email.trim(),
        new_password: newPassword.trim(),
      });

      setMessage(response.data.message);
      setStep(4); // Process completion
      setTimeout(() => {
        navigate('/'); // Redirect to the homepage after successful password reset
      }, 2000); // Delay before redirect
    } catch (error) {
      console.error("Error in handleNewPassword:", error); // Logging
      if (error.response) {
        setMessage(error.response.data.error || 'An error occurred.');
      } else if (error.request) {
        setMessage('No response received from server.');
      } else {
        setMessage('Request failed: ' + error.message);
      }
    }
  };

  const handleCodeInputChange = (index, value) => {
    // Limit input to one character and focus next input automatically
    if (value.length <= 1) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      
      // Automatically focus next input
      if (value && index < code.length - 1) {
        document.getElementById(`code-input-${index + 1}`).focus();
      }
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div className="forgot-password-container">
      <style>
        {`
          /* Container for the entire forgot password page, centering contents vertically and horizontally */
          /* Container for the entire forgot password page, centering contents vertically and horizontally */
.forgot-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Full viewport height */
    background-color: #ffffff; /* Background color for the whole page */
}

/* Styling for the forgot password form */
.forgot-password-form {
    background: white; /* Background color for the form */
    padding: 2rem; /* Space inside the form */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
    width: 100%; /* Full width of the container */
    max-width: 400px; /* Max width of the form */
    text-align: center; /* Center the text inside the form */
}

/* Styling for the logo */
.forgot-password-logo {
    width: 250px; /* Width of the logo */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 1rem; /* Space below the logo */
}

/* Styling for the form header */
.forgot-password-form h2 {
    font-size: 1.5rem; /* Font size of the header */
    margin-bottom: 1.5rem; /* Space below the header */
    color: #333333; /* Darker text color */
}

/* Styling for the form groups */
.form-group {
    margin-bottom: 1.5rem; /* Space below each form group */
}

/* Styling for the labels of the input fields */
.form-group label {
    display: block; /* Display as block to take full width */
    margin-bottom: 0.5rem; /* Space below the label */
    font-weight: bold; /* Bold font */
    color: #333333; /* Darker text color */
}

/* Styling for the input fields */
.form-group input {
    width: 100%; /* Full width of the container */
    padding: 0.5rem; /* Space inside the input */
    border: 1px solid #ccc; /* Border styling */
    border-radius: 4px; /* Rounded corners */
    font-size: 1rem; /* Font size */
    background-color: #f1f1f1; /* Light gray background for inputs */
    color: #333333; /* Dark text color */
}

/* Styling for the verification code input fields */
.verification-code-inputs {
    display: flex; /* Display as flexbox */
    justify-content: space-between; /* Space out the inputs evenly */
    margin-bottom: 1rem; /* Space below the verification code inputs */
}

/* Styling for each verification code input */
.verification-code-input {
    width: 3rem; /* Width of each input */
    height: 3rem; /* Height of each input */
    text-align: center; /* Center the text inside the input */
    font-size: 1.5rem; /* Font size of the input */
    margin-right: 0.5rem; /* Space between inputs */
    background-color: #f1f1f1; /* Light gray background color */
    border: 1px solid #ccc; /* Border styling */
    border-radius: 4px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
    color: #333333; /* Dark text color */
}

/* Remove the margin for the last verification code input */
.verification-code-input:last-child {
    margin-right: 0;
}

/* Styling for the submit button */
.btn-primary {
    width: 100%; /* Full width of the container */
    padding: 0.75rem; /* Space inside the button */
    font-size: 1rem; /* Font size */
    font-weight: bold; /* Bold font */
    cursor: pointer; /* Pointer cursor on hover */
    background-color: #000000; /* Black background */
    color: #ffffff; /* White text */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
}

/* Styling for the submit button on hover */
.btn-primary:hover {
    background-color: #333333; /* Darker background on hover */
}

/* Styling for the alert messages */
.alert {
    font-size: 0.9rem; /* Font size of the alert */
    margin-top: 1rem; /* Space above the alert */
    background-color: #e0e0e0; /* Gray background color */
    color: #333333; /* Dark text color */
    border-radius: 4px; /* Rounded corners */
    padding: 0.75rem; /* Padding for alerts */
    border: none; /* Remove border */
}

/* Loading screen container */
.loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height */
    background-color: #f8f9fa; /* Light background for loading screen */
}

/* Loading logo styling */
.loading-logo {
    width: 150px; /* Width of the loading logo */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Space below the logo */
}

/* Loading spinner styling */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1); /* Light border */
    border-left-color: #343a40; /* Darker border on the left */
    border-radius: 50%; /* Round shape */
    width: 36px; /* Width of the spinner */
    height: 36px; /* Height of the spinner */
    animation: spin 1s linear infinite; /* Infinite rotation animation */
}

@keyframes spin {
    to {
        transform: rotate(360deg); /* Full rotation */
    }
}

/* Additional links container */
.additional-links {
    display: flex;
    justify-content: space-between; /* Space links evenly */
    margin-top: 1rem; /* Space above the links */
}

.additional-links button {
    background: none; /* Remove default button background */
    border: none; /* Remove default button border */
    color: #f5f7f9; /* Button text color */
    font-size: 0.9rem; /* Font size */
    text-decoration: none; /* Remove underline */
    cursor: pointer; /* Pointer cursor on hover */
    transition: color 0.3s ease; /* Smooth transition for text color */
}

/* Styling for the additional links on hover */
.additional-links button:hover {
    color: #000000; /* Darker color on hover */
    text-decoration: underline; /* Underline on hover */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 576px) {
    .forgot-password-form {
        padding: 1.5rem; /* Reduce padding */
        max-width: 90%; /* Reduce max width */
    }

    .forgot-password-logo {
        width: 120px; /* Reduce logo size */
    }

    .verification-code-input {
        width: 2.5rem; /* Reduce input size */
        height: 2.5rem; /* Reduce input size */
        font-size: 1.2rem; /* Reduce font size */
        margin-right: 0.4rem; /* Adjust spacing */
    }
}

        `}
      </style>

      <form className="forgot-password-form">
        <img src={logo} alt="Company Logo" className="forgot-password-logo" />
        <h2>Forgot Password</h2>
        {message && <p className="alert alert-info">{message}</p>}

        {step === 1 && (
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className="btn btn-primary mt-3" type="button" onClick={handleRequestReset}>
              Send Verification Code
            </button>
          </div>
        )}

        {step === 2 && (
          <div className="form-group">
            <label htmlFor="code">Verification Code</label>
            <div className="verification-code-inputs">
              {code.map((digit, index) => (
                <input
                  key={index}
                  id={`code-input-${index}`}
                  type="text"
                  className="form-control verification-code-input"
                  value={digit}
                  onChange={(e) => handleCodeInputChange(index, e.target.value)}
                  maxLength="1" // Ensure only one character per input
                />
              ))}
            </div>
            <button className="btn btn-primary mt-3" type="button" onClick={handleVerifyCode}>
              Verify Code
            </button>
            <p className="mt-2">Time remaining: {formatTime(timer)}</p> {/* Display the timer */}
          </div>
        )}

        {step === 3 && (
          <div className="form-group">
            <label htmlFor="newPassword">New Password</label>
            <input
              type="password"
              className="form-control"
              id="newPassword"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <button className="btn btn-primary mt-3" type="button" onClick={handleNewPassword}>
              Reset Password
            </button>
          </div>
        )}

        {step === 4 && <p className="alert alert-success">Password has been reset successfully. Redirecting...</p>}
      </form>
    </div>
  );
};

export default ForgotPassword;
